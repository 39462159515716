
<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">

<div class="contentWrapper pb-30">
    <div class="loaderHolder" *ngIf="apiStatus === 'pending'">
        <mat-spinner></mat-spinner>
    </div>
    <div class="breadcrumbs"><a #headerLink routerLink='/'>{{'SupportGuide.Home' | translate}}</a> > {{'SupportGuide.SupportGuide' | translate}} <span *ngIf="activeCategory != ''"> > {{activeCategory}}</span></div>
    <div class="page-header inline-element mt-20 mr-20">{{'SupportGuide.SupportGuide' | translate}}</div>
    <a [routerLink]="['/about-the-support-guide']" class="inline-element"><img class="tooltip_image mr-10" src="../../../assets/logos/tool_tip.png" alt="{{'SupportGuide.TooltipImage' | translate}}"><span class="niceLineFromSomething colorItLikeLink colorItLikeDarkLink">{{'SupportGuide.AboutSG' | translate}}</span></a>
    <div class="mt-30 sg-wrapper flex-layout">
        <div class="sg-left-column">            
            <mat-form-field class="sg-search">
                <span matPrefix> </span>
                <input type="text" matInput placeholder="{{'SupportGuide.SearchPlaceholder' | translate}}" aria-label="search text box with auto complete" name="search" [(ngModel)]="searchTerm" #searchInput (keyup.enter)="searchItem(searchInput.value)">
                <button matSuffix mat-button class="search-button" (click)="searchItem(searchTerm)" [disabled]="!searchTerm" aria-label="search"><mat-icon >search</mat-icon></button>
            </mat-form-field>
            <div class="sg-inner-block desktop-block" role="navigation" aria-label="Categories">
                <div class="sg-left-item" tabindex="0" [id]="card.id" (click)="changeItemCategory($event, null, card.id, card.title, card.bannerUrl)" (keyup.enter)="changeItemCategory($event, null, card.id, card.title, card.bannerUrl)" *ngFor="let card of initialCards" [attr.bannerUrl]="card.bannerUrl"> <div class="wrapIcon">
                    <img [src]="card.thumbnailUrl" alt="{{ card.title }} Icon"></div>  {{card.title}}
                </div>
            </div>
            <div class="sg-inner-block mobile-block mobile-dropdown">
                <mat-form-field>
                    <mat-label>{{'SupportGuide.SelectCategory' | translate}}</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let card of initialCards" [value]="card?.title" [id]="card?.id" (click)="changeItemCategoryMobile(null, card?.id, card.title, card.bannerUrl)">
                            <div class="selected-option-container">
                                <img [src]="card.thumbnailUrl" alt="{{ card.title }} Icon" class="selected-option-image">
                                <span class="selected-option-title">{{ card.title }}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
        </div>
        <div class="sg-right-column">
            <div class="category-big-image" *ngIf="bannerURL">
                <img class="category-banner" src="{{bannerURL}}" alt="{{'SupportGuide.CategoryBannerImage' | translate}}">
            </div>
            <div id="content-type-click" class="sg-top-selector-row">
                <div class="sg-selector active" id="content-type-all" (click)="changeItem($event, null, categoryId)">{{'SupportGuide.All' | translate}}</div>
                <div class="sg-selector" [id]="item.id" tabindex="0" (click)="changeItem($event, item.id, categoryId)" *ngFor="let item of resContentTypesForHTML" [innerHTML]="item?.name"></div>
            </div>
            <div >
                <div class="sg-inner-block mb-20 p-20" *ngIf="establishBlock.length > 0">
                    <div class="mb-10 ml-20 sg-page-header">{{'SupportGuide.Establish' | translate}}</div> 
                    <div class="custom-tooltip">
                        <img class="tooltip_image" (click)="showTooltip(establishTooltip)" src="../../../assets/logos/tool_tip.png" alt="Tooltip Image"> 
                         <div class="tooltip-content" id="establishTooltip" #establishTooltip>
                          <img src="../../../assets/triangle.png" alt="Tooltip Icon" class="tooltip-arrow">
                            <p>{{'SupportGuide.EstablishPopup' | translate}}</p>
                            <button mat-button  class="main-button got-it-btn" (click)="hideTooltip(establishTooltip)">{{'SupportGuide.InformationalModalBtn' | translate}}</button>
                         </div>
                      </div>
                    <div class="makeLinkHoverable sg-right-element mb-10" *ngFor="let establishItem of establishBlock">
                        <a [routerLink]="'/support-guide/details/' + establishItem.resourceId" (click)="resourceGA4Push(establishItem.category, establishItem.title, establishItem.contentType, 'Support Guide')">
                            <div class="page-header sg-item">
                                <div class="wrapIcon">
                                    <img [src]="establishItem.categoryThumbnailUrl" alt="{{establishItem.category}} Icon">
                                </div>
                                <div class="sg-item-title">{{establishItem.title}}</div>
                            
                                <div class="sg-item-icon-block">                            
                                    <div class="sg-arrow">
                                        <img class="non-active-arrow" src="../../../assets/logos/icon-arrow.png" alt="Arrow Image">
                                        <img class="active-arrow" src="../../../assets/logos/arrow_right_blue.png" alt="Arrow Image">
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div class="sg-bookmarked">
                            <img class="clickedBookmarked" tabindex="0" (click)="onBookmarkIconClick({ resourceId: establishItem.resourceId, isFavorite: establishItem.isFavorite }, establishItem.title)" src="../../../assets/logos/bookmarked-true.png" alt="Bookmark Image" *ngIf="establishItem.isFavorite">
                            <img class="notClickedBookmarked" tabindex="0" (click)="onBookmarkIconClick({ resourceId: establishItem.resourceId, isFavorite: establishItem.isFavorite }, establishItem.title)" src="../../../assets/logos/bookmark_item.png" alt="Bookmark Image" *ngIf="!establishItem.isFavorite">
                        </div>
                    </div>
                </div>
                <div class="sg-inner-block mb-20 p-20" *ngIf="expandBlock.length > 0">
                    <div class="mb-10 ml-20 sg-page-header">{{'SupportGuide.Expand' | translate}}</div>
                    <div class="custom-tooltip">
                        <img class="tooltip_image" (click)="showTooltip(epandTooltip)" src="../../../assets/logos/tool_tip.png" alt="Tooltip Image"> 
                         <div class="tooltip-content" id="epandTooltip" #epandTooltip>
                          <img src="../../../assets/triangle.png" alt="Tooltip icon" class="tooltip-arrow">
                            <p>{{'SupportGuide.ExpandPopup' | translate}}</p>
                            <button mat-button  class="main-button got-it-btn" (click)="hideTooltip(epandTooltip)">{{'SupportGuide.InformationalModalBtn' | translate}}</button>
                         </div>
                      </div>
                    <div class="makeLinkHoverable sg-right-element mb-10" *ngFor="let expandItem of expandBlock">
                        <a [routerLink]="'/support-guide/details/' + expandItem.resourceId" (click)="resourceGA4Push(expandItem.category, expandItem.title, expandItem.contentType, 'Support Guide')">
                            <div class="page-header sg-item">
                                <div class="wrapIcon">
                                    <img [src]="expandItem.categoryThumbnailUrl" alt="{{expandItem.category}} Icon">
                                </div>
                                <div class="sg-item-title">{{expandItem.title}}</div>
                                <div class="sg-item-icon-block">
                                    <div class="sg-arrow">
                                        <img class="non-active-arrow" src="../../../assets/logos/icon-arrow.png" alt="Arrow Image">
                                        <img class="active-arrow" src="../../../assets/logos/arrow_right_blue.png" alt="Arrow Image">
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div class="sg-bookmarked">
                            <img class="clickedBookmarked" tabindex="0" (click)="onBookmarkIconClick({ resourceId: expandItem.resourceId, isFavorite: expandItem.isFavorite }, expandItem.title)" src="../../../assets/logos/bookmarked-true.png" alt="Bookmark Image" *ngIf="expandItem.isFavorite">
                            <img class="notClickedBookmarked" tabindex="0" (click)="onBookmarkIconClick({ resourceId: expandItem.resourceId, isFavorite: expandItem.isFavorite }, expandItem.title)" src="../../../assets/logos/bookmark_item.png" alt="Bookmark Image" *ngIf="!expandItem.isFavorite">
                        </div>
                    </div>
                </div>
                <div class="sg-inner-block p-20" *ngIf="optimizeBlock.length > 0" >
                    <div class="mb-10 ml-20 sg-page-header">{{'SupportGuide.Optimize' | translate}}</div>
                    <div class="custom-tooltip">
                        <img class="tooltip_image" (click)="showTooltip(optimizeTooltip)" src="../../../assets/logos/tool_tip.png" alt="Tooltip Image"> 
                         <div class="tooltip-content" id="optimizeTooltip" #optimizeTooltip>
                          <img src="../../../assets/triangle.png" alt="Tooltip Icon" class="tooltip-arrow">
                            <p>{{'SupportGuide.OptimizePopup' | translate}}</p>
                            <button mat-button  class="main-button got-it-btn" (click)="hideTooltip(optimizeTooltip)">{{'SupportGuide.InformationalModalBtn' | translate}}</button>
                         </div>
                      </div>
                    <div class="makeLinkHoverable sg-right-element mb-10" *ngFor="let optimizeItem of optimizeBlock">
                        <a [routerLink]="'/support-guide/details/' + optimizeItem.resourceId" (click)="resourceGA4Push(optimizeItem.category, optimizeItem.title, optimizeItem.contentType, 'Support Guide')">
                            <div class="page-header sg-item">
                                <div class="wrapIcon">
                                    <img [src]="optimizeItem.categoryThumbnailUrl" alt="{{optimizeItem.category}} Icon">
                                </div>
                                <div class="sg-item-title">{{optimizeItem.title}}</div>
                                <div class="sg-item-icon-block">
                                    <div class="sg-arrow">
                                        <img class="non-active-arrow" src="../../../assets/logos/icon-arrow.png" alt="Arrow Image">
                                        <img class="active-arrow" src="../../../assets/logos/arrow_right_blue.png" alt="Arrow Image">
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div class="sg-bookmarked">
                            <img class="clickedBookmarked" tabindex="0" (click)="onBookmarkIconClick({ resourceId: optimizeItem.resourceId, isFavorite: optimizeItem.isFavorite }, optimizeItem.title)" src="../../../assets/logos/bookmarked-true.png" alt="Bookmark Image" *ngIf="optimizeItem.isFavorite">
                            <img class="notClickedBookmarked" tabindex="0" (click)="onBookmarkIconClick({ resourceId: optimizeItem.resourceId, isFavorite: optimizeItem.isFavorite }, optimizeItem.title)" src="../../../assets/logos/bookmark_item.png" alt="Bookmark Image" *ngIf="!optimizeItem.isFavorite">
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-paginator" *ngIf="pagesList.length > 0">
                {{'SupportGuide.Page' | translate}}&nbsp;&nbsp;&nbsp;
                <div id="arrowPrev" class="prev-page hidden" *ngIf="pagesList.length > 1">
                    <img src="../../../assets/arrow-left.png" alt="arrow-left" tabindex="0" (click)="changePage(prevPage, true)">
                </div>
                <div id="page{{page}}" class="custom-page" [ngClass]="{ 'active': page === activePage }" *ngFor="let page of pagesList" tabindex="0" (click)="changePage(page, null)"><span class="justForScreenReader">page</span> {{page}}</div>
                <div id="arrowNext" class="next-page" *ngIf="pagesList.length > 1">
                    <img src="../../../assets/arrow-right.png" alt="arrow-right" tabindex="0" (click)="changePage(nextPage, false)">
                </div>
            </div>
            <div *ngIf="pagesList.length == 0 && apiStatus !== 'pending'">
                <div class="no-results-text mb-10"><h2 class="errorHeader">{{'SupportGuide.ResourceErrorTitle' | translate}}</h2></div>
                <div class="no-results-text mb-10 errorMessageHolder">{{'SupportGuide.ResourceErrorText1' | translate}}</div>
                <div class="no-results-text mb-10 errorMessageHolder">{{'SupportGuide.ResourceErrorText2' | translate}}</div>
                <div class="no-results-text mb-10 errorMessageHolder">{{'SupportGuide.ContactDetails' | translate}} <a href="mailto:FGEgo@learning-help.com">{{'SupportGuide.ContactDetailsMail' | translate}}</a></div>
            </div>
        </div>       
    </div>
</div>

</div>