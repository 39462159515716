import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  constructor(private http: HttpClient) {}

  getFeatures(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/metadata/features`);
  }

  getProgress(featureCode: string): Observable<any> {  
    return this.http.get<any>(`${environment.apiUrl}/progress?feature=${featureCode}`);
  }

  postProgress(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/progress`, payload);    
  }

  postProgressSecondSection(payloadSecondSection: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/progress`, payloadSecondSection);
  }
}
