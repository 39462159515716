<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">

<div class="contentWrapper">
    <nav class="navigationForOnboardingComponent navigationWithPositionAbsolute">
        <a routerLink='/'>{{'Profile.Home' | translate}}</a>       
        <span> > </span>
        <span>{{'Profile.Profile' | translate}}</span>
    </nav>

    <div class="content-title">{{'Profile.MyProfile' | translate}} 
        <!-- <img class="tooltip_image" mat-raised-button
        matTooltip="Info about the action"
        matTooltipPosition="above"
         src="../../../assets/logos/tool_tip.png" alt="Tooltip Image"> -->
    </div>

    <mat-card class="mt-30 profile-card">
        <mat-card-content>
            <div class="profile-img-block">
                <img src="../../../assets/logos/profile_image_new.png" alt="Profile Image">
            </div>
            <div class="profile-main-info">
                <div class="content-title">{{firstName}}  {{lastName}}</div>
                <div class="content-text mt-10">
                    <span>{{dealerName}}</span>
                    <span style="display: none;">Dealership number</span>                  
                </div>
                <div class="content-text mt-10">
                    <span>{{storeId}}</span>
                    <span style="display: none;">Dealership number</span> 
                </div>
            </div>
            <div class="profile-change-lang" style="display: none;">
                <a routerLink="/" >Change Language</a>
            </div>
            <div class="profile-btm-section"  style="display: none;">300 x 300px recommended resolution</div>
        </mat-card-content>
    </mat-card>
    </div>
</div>
