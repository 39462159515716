<mat-drawer-container autosize="">
    <mat-drawer #drawer opened="false" mode="push" position="end">
        <mat-nav-list>
            <!-- <mat-list-item> -->
                <section class="wrapSlideMenuHeader">
                    <p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.JumpToSection" | translate}}</p>
                    <a id="dnload-wkst" class="makeCertainSize" mat-raised-button href="{{'SharedItems.WorkSheetUrl' | translate}}" target="_blank" (click)="downloadGA4Push()">{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.ViewWorksheet" | translate}}</a>
                </section>
                <section class="wrapSlideMenuBody">
                    <div class="headerDivInSlideMenuBody">
                        <h4><b>{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionFirst" | translate}}</b></h4>
                        <p>{{ getCompletedSectionsCount() }} {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.Of_Eleven_Completed" | translate}}</p>
                    </div>

                    <section class="mainNavSectionInSlideMenuBody">
                    <!-- First Part -->
                        <div class="wrapDotsAndWords">
                            <div class="navigationDotsInSlideMenu">
                                <ng-container *ngFor="let status of sectionStatus; let i = index; let last = last">
                                    <a class="dots" [ngClass]="{ 'outerBorder': status || currentSectionIndex === i }">
                                        <div class="mini-dot" [ngClass]="{ 'activeDot': status }">
                                            <svg id="icon_-_checkmark_small" data-name="icon - checkmark small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
                                                <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff"/>
                                                <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff"/>
                                            </svg>                                              
                                        </div>
                                    </a>
                                    <span *ngIf="!last"></span>
                                </ng-container>
                            </div>
                            <div class="textCloseToDotsInSideMenu" *ngIf="marketValue !='US'">
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="diversity_pre"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionOne" | translate}}</p>
                                    <span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg>                                      
                                    </span>
                                </span></a>                                
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="gender"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>                                
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="age"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>                                
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="generation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="evs"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>                               
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="online_sales"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>                               
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest_personas"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSeven" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>                              
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEight" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>                               
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest_representation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionNine" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTen" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEleven" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                </div>


                                <div class="textCloseToDotsInSideMenu" *ngIf="marketValue =='US'">                                    
                                    <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="Inclusive_Culture"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionOne" | translate}}</p>
                                        <span class="arrowheadInSideMenu">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                                <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                                <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                                <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                                </g>
                                            </svg>                                      
                                        </span>
                                    </span></a>
                                    <span></span>                                    
                                    <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="ev"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                    <span></span>                                  
                                    <a (click)="closeSideMenu()" ><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="online_sales"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                    <span></span>                                 
    
                                    <a (click)="closeSideMenu()" ><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="ford_guests"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                    <span></span>                                    
                                    <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                    <span></span>                                   
                                    <a (click)="closeSideMenu()" ><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest-representation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                    <span></span>
                                   
                                    <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSeven" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                    <span></span> 
                                    
                                    <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEight" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                </div>
                        </div>
                    </section>
                </section>


                <section class="wrapSlideMenuBody">
                    <div class="headerDivInSlideMenuBody">
                        <h4><b>{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionSecond" | translate}}</b></h4>
                        <p>{{ getCompletedSecondSectionsCount() }} {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.Of_Six_Completed" | translate}}</p>
                    </div>

                    <section class="mainNavSectionInSlideMenuBody">
                        <div class="wrapDotsAndWords">
                            <div class="navigationDotsInSlideMenu">
                                <ng-container *ngFor="let secondStatus of secondSectionStatus; let i = index; let last = last">
                                  <a class="dots" [ngClass]="{ 'outerBorder': secondStatus }">
                                    <div class="mini-dot" [ngClass]="{ 'activeDot': secondStatus }">
                                        <svg id="icon_-_checkmark_small" data-name="icon - checkmark small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff"/>
                                        </svg>
                                    </div>
                                  </a>
                                  <span *ngIf="!last"></span>
                                </ng-container>
                            </div>
                            <div class="textCloseToDotsInSideMenu">
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="customer_to_guest"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionOne" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="fge_vision"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="fge_principles"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="we_have_got_you"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="treating_guests_like_family"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="what_is_in_it_for_me"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                            </div>
                        </div>
                    </section>
                </section>
                <button class="closeButtonInSideMenu" mat-raised-button (click)="closeSideMenu()">
                    <div class="wrapTextAndSVGInSideMenu">
                        <p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.CloseMenu" | translate}}</p>                     
                        <svg id="icon_-_close_medium" data-name="icon - close medium" xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.556" viewBox="0 0 15.557 15.556">
                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef"/>
                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef"/>
                          </svg> 
                    </div>
                </button>
                <!-- </mat-list-item> -->
        </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content class="customColorForDrawer" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
        <div class="outerComponentColor customScrollInAnimatedContent">
            <div class="wrapMapComponent">
                <!-- max speed for data-rellax-speed is 10 and -10 --> 
                <nav class="navigationForOnboardingComponent navigationWithPositionAbsolute">
                    <div>
                        <a routerLink='/'>{{'DetailedSectionOne.localTopNavigation.Home' | translate}}</a>
                        <span> > </span>
                        <span>{{"DetailedSectionOne.localTopNavigation.AboutFGE" | translate}}</span>
                    </div>
                    <div>
                        <h1>{{"DetailedSectionOne.ReadMoreSection.WhyFGE" | translate}}</h1>
                    </div>
                </nav>
                <div class="sectionBeforeAnimatedContent">
                    <!-- <div class="firstPart">
                      <p>Ford Guest Experience (FGE) is our collective opportunity to transform our mindset from customer service to Guest experience. Customer service is transactional, whereas Guest experience is emotional. It is the foundation of a relationship that can grow into long-term Guest loyalty.</p>
                      <p [class]="isContentShown ? 'show showBlock' : 'noShow'"><br />Ford Guest Experience is built on two key pillars: <i>People and how we treat them</i>, and the <i>experiences we deliver for those people</i>.</p><br />
                      <p [class]="isContentShown ? 'show showBlock' : 'noShow'">It is Guest-centric, which means putting the Guest at the center of everything we do. It focuses on the importance of a diverse, equitable, and inclusive culture for Guests and employees, ensuring that we are delivering on their needs and expectations.</p>
                    </div>
                    <div class="secondPart">
                      <p>It comes to life through the ownership experiences of FordPass Rewards, Connected Vehicles with the FordPass app, and Remote Services, including Pickup & Delivery and Mobile Service.</p><br />
                      <p [class]="isContentShown ? 'show showBlock' : 'noShow'">To be effective, Ford Guest Experience needs to be delivered consistently: every Guest, every time. Guests want a seamless, always-on experience, whether remote or in person, and they want us to learn what is important to them so that we can provide the things that are most relevant to their journey, needs, and lifestyle.  When we succeed at this, we will differentiate ourselves and earn their loyalty.</p>
                      <span class="niceLineFromSomething" (click)="toggleContent()">{{ isContentShown ? 'Hide' : 'Read More' }}</span>
                    </div> -->
                    <div class="customSingleParagraphs">
                        <p>{{"DetailedSectionOne.ReadMoreSection.ReadMoreSectionParagraphOne" | translate}}</p>
                        <p [innerHTML]="'DetailedSectionOne.ReadMoreSection.ReadMoreSectionParagraphTwo' | translate | formatHtml"></p>
                        <p [class]="isContentShown ? 'show showBlock' : 'noShow'">{{"DetailedSectionOne.ReadMoreSection.ReadMoreSectionParagraphThree" | translate}}</p>
                        <p [class]="isContentShown ? 'show showBlock' : 'noShow'">{{"DetailedSectionOne.ReadMoreSection.ReadMoreSectionParagraphFour" | translate}}</p>
                        <p [class]="isContentShown ? 'show showBlock' : 'noShow'">{{"DetailedSectionOne.ReadMoreSection.ReadMoreSectionParagraphFive" | translate}}</p>
                        <span class="niceLineFromSomething" (click)="toggleContent()" id="diversity_pre">
                            {{ isContentShown ? ('DetailedSectionOne.ReadMoreSection.HideButton' | translate) : ('DetailedSectionOne.ReadMoreSection.ReadMoreButton' | translate) }}
                        </span>  
                    </div>
                  </div>  
                  
                  
                <!-- Section 1 -->  
                <span *ngIf="marketValue !='US'" class="Section1 articleHolder">  
                <article class="content firstContainer" id="diversity">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="3">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionOneContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionOneContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionOneContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionOneContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Diversity')">{{"DetailedSectionOne.SectionOneContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow" id="firstImageSection1Part1" src="{{'DetailedSectionOne.SectionOneContent.ImageSource2' | translate}}" alt="{{'DetailedSectionOne.SectionOneContent.ImageAlt' | translate}}">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="secondImageSection1Part1" src="{{'DetailedSectionOne.SectionOneContent.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionOneContent.ImageAlt' | translate}}">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow {{'DetailedSectionOne.SectionOneContent.SpecialInctructionsForGB' | translate}}" id="thirdImageSection1Part1" src="{{'DetailedSectionOne.SectionOneContent.ImageSource1' | translate}}" alt="{{'DetailedSectionOne.SectionOneContent.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>
                
                <span *ngIf="marketValue == 'US'" class="Section1 articleHolder" id="Inclusive_Culture">
                    <article class="content firstContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="-2.5">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionOneContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionOneContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionOneContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionOneContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Age')">{{"DetailedSectionOne.SectionOneContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="seventhImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image1_section1_us.png" alt="{{'DetailedSectionOne.SectionOneViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="eighthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image1_section2_us.png" alt="{{'DetailedSectionOne.SectionOneViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                </span>
                <!-- Section 1 End -->


                <!-- Section 2 -->
                <span *ngIf="marketValue != 'US'" class="Section2 articleHolder" id="gender">            
                    <article class="content secondContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="10">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionTwoContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionTwoContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionTwoContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionTwoContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Gender')">{{"DetailedSectionOne.SectionTwoContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="forthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image6_section1.png" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="zoom-in-right" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="fifthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image5_section1.png" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="zoom-in-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="sixthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image4_section1.png" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                </span>

                <span *ngIf="marketValue == 'US'" class="Section2 articleHolder" id="ev">
                    <article class="content secondContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="9">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionTwoContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionTwoContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionTwoContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionTwoContent.UniqueID' | translate]" (click)="sendGA4NameInfo('EVs')">{{"DetailedSectionOne.SectionTwoContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="twelfthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image12_section1.png" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-down" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="thirteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image13_section1.png" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="fourteenthImageSection1Part1" src="{{'DetailedSectionOne.SectionTwoContent.ImageForTranslation' | translate}}" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                    </span>


                <!-- Section 2 End -->

                <!-- Section 3 -->
                <span *ngIf="marketValue != 'US'" class="Section3 articleHolder" id="age">
                <article class="content thirdContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="-2.5">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionThreeContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionThreeContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionThreeContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionThreeContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Age')">{{"DetailedSectionOne.SectionThreeContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="seventhImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image7_section1.png" alt="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="eighthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image8_section1.png" alt="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>

                <span *ngIf="marketValue == 'US'" class="Section3 articleHolder" id="online_sales">
                    <article class="content thirdContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="-2.5">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionThreeContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionThreeContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionThreeContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionThreeContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Online Sales')">{{"DetailedSectionOne.SectionThreeContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="fiveteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image15_section1.png" alt="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="sixteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image16_section1.png" alt="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="flip-down" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="seventeenthImageSection1Part1" src="{{'DetailedSectionOne.SectionThreeContent.ImageForTranslation' | translate}}" alt="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                    </span>
                <!-- Section 3 End -->

                <!-- Section 4 -->
                <span  *ngIf="marketValue != 'US'" class="Section4 articleHolder" id="generation">
                <article class="content fourthContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="3">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionFourContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionFourContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionFourContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionFourContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Generation')">{{"DetailedSectionOne.SectionFourContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="ninthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image9_section1.png" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="tenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image10_section1.png" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="eleventhImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image11_section1.png" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>

                <span *ngIf="marketValue == 'US'" class="Section4 articleHolder" id="ford_guests">
                    <article class="content fourthContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="10">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionFourContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionFourContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionFourContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionFourContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Ford Guests')">{{"DetailedSectionOne.SectionFourContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="fade-down" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="eighteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image18_section1.png" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="nineteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image19_section1.png" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="twentiethSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image20_section1.png" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                    </span>

                <!-- Section 4 End -->


                <!-- Section 5 -->
                <span *ngIf="marketValue != 'US'" class="Section5 articleHolder" id="evs">
                <article class="content fifthContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="9">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionFiveContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionFiveContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionFiveContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionFiveContent.UniqueID' | translate]" (click)="sendGA4NameInfo('EVs')">{{"DetailedSectionOne.SectionFiveContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="twelfthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image12_section1.png" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-down" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="thirteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image13_section1.png" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="fourteenthImageSection1Part1" src="{{'DetailedSectionOne.SectionFiveContent.ImageForTranslation' | translate}}" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>

                <span *ngIf="marketValue == 'US'" class="Section5 articleHolder" id="happy_brain_anxious_brain">
                    <article class="content fifthContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="-3">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionFiveContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionFiveContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionFiveContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionFiveContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Happy Brain / Anxious Brain')">{{"DetailedSectionOne.SectionFiveContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="twentyfirstSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image21_section1.png" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="twentysecondImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image22_section1.png" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="twentythirdImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image23_section1.png" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                    </span>
                <!-- Section 5 End -->

                <!-- Section 6 -->
                <span *ngIf="marketValue !='US'" class="Section6 articleHolder" id="online_sales">
                <article class="content sixthContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="-2.5">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionSixContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionSixContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionSixContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionSixContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Online Sales')">{{"DetailedSectionOne.SectionSixContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="fiveteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image15_section1.png" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="sixteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image16_section1.png" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="flip-down" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="seventeenthImageSection1Part1" src="{{'DetailedSectionOne.SectionSixContent.ImageForTranslation' | translate}}" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>

                <span *ngIf="marketValue =='US'" class="Section6 articleHolder" id="guest-representation">
                    <article class="content sixthContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="6">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionSixContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionSixContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionSixContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionSixContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Guest Representation')">{{"DetailedSectionOne.SectionSixContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="twentyfourthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image24_section1.png" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="1500" class="customImageInTheMainFlow " id="twentyfifthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image25_section1.png" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="500" class="customImageInTheMainFlow" id="twentysixthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image26_section1.png" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                    </span>
                <!-- Section 6 End -->

                <!-- Section 7 -->
                <span *ngIf="marketValue !='US'" class="Section7 articleHolder" id="guest_personas">
                <article class="content seventhContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="10">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionSevenContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionSevenContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionSevenContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionSevenContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Ford Guests')">{{"DetailedSectionOne.SectionSevenContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="fade-down" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="eighteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image18_section1.png" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="nineteenthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image19_section1.png" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="twentiethSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image20_section1.png" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>

                <span *ngIf="marketValue =='US'" class="Section7 articleHolder" id="relationship_building_with_guests">
                    <article class="content seventhContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="-3">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionSevenContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionSevenContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionSevenContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionSevenContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Relationship-building with Guests')">{{"DetailedSectionOne.SectionSevenContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="twentyseventhImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image27_section1.png" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="zoom-out" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="twentyeighthSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image28_section1.png" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="fade-down" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="twentyninthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image29_section1.png" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                    </span>
                <!-- Section 7 End -->

                <!-- Section 8 -->
                <span *ngIf="marketValue !='US'" class="Section8 articleHolder" id="happy_brain_anxious_brain">
                <article class="content eighthContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="-3">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionEightContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionEightContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionEightContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionEightContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Happy Brain / Anxious Brain')">{{"DetailedSectionOne.SectionEightContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="twentyfirstSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image21_section1.png" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="twentysecondImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image22_section1.png" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="twentythirdImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image23_section1.png" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>

                <span *ngIf="marketValue =='US'" class="Section8 articleHolder" id="last_best_experience">
                    <article class="content eighthContainer">
                        <div class="wrapAnimatedContent">
                            <div class="leftText rellax" data-rellax-speed="3">
                                <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionEightContent.SectionNumber" | translate}}</p>
                                <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionEightContent.SectionName" | translate}}</h3>
                                <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionEightContent.SectionDescription" | translate}}</p>
                                <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionEightContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Be Your Guest’s Last Best Experience')">{{"DetailedSectionOne.SectionEightContent.VeiwSectionButton" | translate}}</a>
                            </div>
                            <div class="animatedImagesHolder">
                                <img data-aos="zoom-out" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="thirtyfirstImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image30_section1.png" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="zoom-out" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="thirtysecondImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image31_section1.png" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">
                                <img data-aos="zoom-out" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="thirtythirdImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image32_section1.png" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">
                            </div>
                        </div>
                    </article>
                    </span>
                <!-- Section 8 End -->

                <!-- Section 9 -->
                <span *ngIf="marketValue !='US'" class="Section9 articleHolder" id="guest_representation">
                <article class="content ninthContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="6">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionNineContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionNineContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionNineContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionNineContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Guest Representation')">{{"DetailedSectionOne.SectionNineContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="twentyfourthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image24_section1.png" alt="{{'DetailedSectionOne.SectionNineViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="1500" class="customImageInTheMainFlow " id="twentyfifthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image25_section1.png" alt="{{'DetailedSectionOne.SectionNineViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="500" class="customImageInTheMainFlow" id="twentysixthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image26_section1.png" alt="{{'DetailedSectionOne.SectionNineViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>
                <!-- Section 9 End -->


                <!-- Section 10 -->
                <span *ngIf="marketValue !='US'" class="Section10 articleHolder" id="relationship_building_with_guests">
                <article class="content tenthContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="-3">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionTenContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionTenContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionTenContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionTenContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Relationship-building with Guests')">{{"DetailedSectionOne.SectionTenContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="twentyseventhImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image27_section1.png" alt="{{'DetailedSectionOne.SectionTenViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-out" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="twentyeighthSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image28_section1.png" alt="{{'DetailedSectionOne.SectionTenViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="fade-down" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="twentyninthImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image29_section1.png" alt="{{'DetailedSectionOne.SectionTenViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>
                <!-- Section 10 End -->

                <!-- Section 11 -->
                <span *ngIf="marketValue !='US'" class="Section11 articleHolder" id="last_best_experience">
                <article class="content eleventhContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="3">
                            <p class="sectionNumberHeader">{{"DetailedSectionOne.SectionElevenContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionOne.SectionElevenContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionOne.SectionElevenContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-1', 'DetailedSectionOne.SectionElevenContent.UniqueID' | translate]" (click)="sendGA4NameInfo('Be Your Guest’s Last Best Experience')">{{"DetailedSectionOne.SectionElevenContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-out" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="thirtyfirstImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image30_section1.png" alt="{{'DetailedSectionOne.SectionElevenViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-out" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="thirtysecondImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image31_section1.png" alt="{{'DetailedSectionOne.SectionElevenViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-out" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="thirtythirdImageSection1Part1" src="../../../assets/interactive_map/main_screen_part1/image32_section1.png" alt="{{'DetailedSectionOne.SectionElevenViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                </span>
                <!-- Section 11 End -->
            </div>
            <div class="explore-btm-btn">
                <a class="sectionLink shortenButton" mat-raised-button [routerLink]="['/onboarding/part-2']">
                    {{"DetailedSectionOne.AnimatedMapFooter.GoToPartTwoButton" | translate}}
                </a>
            </div>

        <!-- Footer -->
        <footer class="footerForMapComponent">
            <div class="wrapfootercomponents">
            <section>
                <p class="flexedP_inFooter {{'DetailedSectionOne.AnimatedMapFooter.ShorterLength' | translate}}">
                    <span class="blueBoldText">
                        {{"DetailedSectionOne.AnimatedMapFooter.LeftSectionOne_Pre" | translate}}
                    </span>
                    <span class="solveSpacing">
                        {{"DetailedSectionOne.AnimatedMapFooter.LeftSectionOne" | translate}}
                    </span>
                <br class="showOnMobileOnly" /> <span class="dontShowOnMobile">|</span> <span class="lightBlueText longWordsShallNotPass">{{sectionTitle | translate}}</span></p>
            </section>

            <div class="containerInMap">
                <ng-container *ngFor="let status of sectionStatus; let i = index; let last = last">
                  <a class="dots" (click)="toggleMenu()" [ngClass]="{ 'outerBorder': status || currentSectionIndex === i }">
                    <div class="mini-dot" [ngClass]="{ 'activeDot': status }"></div>
                  </a>
                  <span *ngIf="!last"></span>
                </ng-container>
              </div>
        
            <section>
                <span class="viewSections">{{"DetailedSectionOne.AnimatedMapFooter.RightSection" | translate}}</span>
                <button (click)="toggleMenu(); hideConfirmation(); closeToastOnClick();" mat-icon-button [class.active]="isActiveButton" aria-label="Example icon-button with menu icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                        <g id="icon_-_hamburger_menu_-_default" data-name="icon - hamburger menu - default" transform="translate(6)">
                          <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="18" rx="1" transform="translate(0 2) rotate(-90)" fill="#1700f4"/>
                          <rect id="Rectangle_169" data-name="Rectangle 169" width="2" height="18" rx="1" transform="translate(0 9) rotate(-90)" fill="#1700f4"/>
                          <rect id="Rectangle_170" data-name="Rectangle 170" width="2" height="18" rx="1" transform="translate(0 16) rotate(-90)" fill="#1700f4"/>
                        </g>
                        <g id="icon_-_hamburger_menu_-_default-2" data-name="icon - hamburger menu - default">
                          <rect id="Rectangle_11-2" data-name="Rectangle 11" width="2" height="3" rx="1" transform="translate(0 2) rotate(-90)" fill="#0f0f0f"/>
                          <rect id="Rectangle_169-2" data-name="Rectangle 169" width="2" height="3" rx="1" transform="translate(0 9) rotate(-90)" fill="#0f0f0f"/>
                          <rect id="Rectangle_170-2" data-name="Rectangle 170" width="2" height="3" rx="1" transform="translate(0 16) rotate(-90)" fill="#0f0f0f"/>
                        </g>
                      </svg>
                </button>
            </section>
            </div>
        </footer>
        </div>
    </mat-drawer-content>
</mat-drawer-container>