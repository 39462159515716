import { Component, OnInit, ViewChild, OnDestroy, NgModule } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { SharedService } from '../../shared.service'; 
import { MatDrawer } from '@angular/material/sidenav';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { ProgressService } from '../../APIs/progress.service';
import AOS from 'aos';
import { FooterService } from '../../shared.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { SharedModule } from '../../shared.module';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../app/shared.service';
import { ToastCommunicationService } from '../../../app/shared.service'; 
import { Subscription } from 'rxjs';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-detailed-section',
  templateUrl: './detailed-section.component.html',
  styleUrls: ['./detailed-section.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, CommonModule, RouterModule, MatListModule, MatSidenavModule, MatIconModule, MatDialogModule, SharedModule],
})
export class DetailedSectionComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: false }) drawer!: MatDrawer;

  sectionIdForModal: string | null = null;

  isActiveButton: boolean = false;
  private lastTimeout: any;

  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;
  showToast: boolean = false;
  marketValue: any  = this.helperService.getMarket()?.toUpperCase();
  showExtraPadding: boolean = false;
  isToastClosed: string = "";

  closeSideMenu() {
    this.drawer.close();
    this.isActiveButton = false;
    window.scrollTo({
      top: 0
    });
    window.addEventListener('scroll', this.onAutoScroll, true);
  }

  private onAutoScroll = (): void => {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }

    this.lastTimeout = setTimeout(() => {
      window.removeEventListener('scroll', this.onAutoScroll, true);
      window.scrollBy({
        top: -100,
        behavior: 'smooth',
      });
    }, 100);
  };

  toggleMenu() {
    this.drawer.toggle();
    this.isActiveButton = !this.isActiveButton;
  }

  getCompletedSectionsCount() {
    return this.sectionStatus.filter(status => status).length;

  }

  getCompletedSecondSectionsCount(){
    return this.secondSectionStatus.filter(status => status).length;
  }

  hideConfirmation() {
    this.showToast = false;
    this.showExtraPadding = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed); 
  }

  closeToastOnClick() {
    this.toastCommunicationService.closeToast();
  }

  sectionStatus: boolean[] = new Array(11).fill(false);
  secondSectionStatus: boolean[] = new Array(6).fill(false);

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger; 
  sectionId: string = '';
  applyClass: boolean = false;
  id: string = '';
  firstSection: boolean = true;
  secondSection: boolean = true; 
  sectionTitle: string = '';

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private progressService: ProgressService,
    private footerService: FooterService,
    private routeService: RouteService,
    private tagService: GA4Service,
    private translateService: TranslateService,
    private toastService: ToastService,
    private toastCommunicationService: ToastCommunicationService,
    private helperService: HelperService
  ) {}

  features: any[] = [];
  progressData: any[] = []; 
  processProgressData(progressData: any[]) { 
    const sectionsAmount = this.sections.length + this.secondSections.length;      
    for (const entry of progressData ) {
      for (let i = 0;   i < sectionsAmount; i++ ){
        if (i < this.sections.length && entry.objectId == this.sections[i].id){          
          if(this.sections[i].firstSection){
            this.sectionStatus[i] = true;
          }         
        } else if (i < this.secondSections.length && entry.objectId == this.secondSections[i].id){          
          if(this.secondSections[i].secondSection){
            this.secondSectionStatus[i] = true;
          }         
        }
      }
    }
  }

  sections: any[] = [
    {
      id: this.translateService.instant('DetailedSectionOne.SectionOneContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionOneViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,      
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionTwoContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionTwoViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionThreeContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionThreeViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionFourContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionFourViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionFiveContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionFiveViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionSixContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionSixViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionSevenContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionSevenViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionEightContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionEightViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionNineContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionNineViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionTenContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionTenViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionOne.SectionElevenContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionOne.SectionElevenViewSectionPage.TopNavLinkThree'),
      firstSection: true,
      applyClass: false,
    }
  ];

  secondSections: any[] = [
    {
      id: this.translateService.instant('DetailedSectionTwo.SectionOneContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionTwo.SectionOneViewSectionPage.TopNavLinkThree'),
      secondSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionTwo.SectionTwoContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionTwo.SectionTwoViewSectionPage.TopNavLinkThree'),
      secondSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionTwo.SectionThreeContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionTwo.SectionThreeViewSectionPage.TopNavLinkThree'),
      secondSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionTwo.SectionFourContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionTwo.SectionFourViewSectionPage.TopNavLinkThree'),
      secondSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionTwo.SectionFiveContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionTwo.SectionFiveViewSectionPage.TopNavLinkThree'),
      secondSection: true,
      applyClass: false,
    },
    {
      id: this.translateService.instant('DetailedSectionTwo.SectionSixContent.UniqueID'),
      title: this.translateService.instant('DetailedSectionTwo.SectionSixViewSectionPage.TopNavLinkThree'),
      secondSection: true,
      applyClass: false,
    }
  ];

  hideFooter() {
    this.footerService.toggleFooterVisibility(false);
  }  
  showFooter() {
    this.footerService.toggleFooterVisibility(true);
  }
  
  ngOnInit(): void {
    this.toastService.showToast$.subscribe(showToast => {
      this.showToast = showToast;
    });

    if (this.marketValue == "US" ) {
      this.sectionStatus = new Array(8).fill(false);
      this.secondSectionStatus = new Array(6).fill(false );
      this.sections.splice(8, 10);
    }

    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }  
    this.hideFooter();
    this.tagService.refreshPush();
    AOS.init();
    // this.setSectionCompleted(this.sectionId);
    this.progressService.getFeatures().subscribe(
      (data: any) => {
        this.features = data.features;
      },
      (error) => {
        this.routeService.serverError(error);
      }
    );
  
    this.progressService.getProgress("OB").subscribe(
      (data: any) => {
        this.progressData = data.progresses;
        this.processProgressData(this.progressData);
      },
      (error) => {
        this.routeService.serverError(error);
      }
    );
    
    this.route.params.subscribe((params) => {
      this.sectionId = params['sectionId'];

      const currentSection = this.sections.find((section) => section.id === this.sectionId);
      if (currentSection) {
        this.id = currentSection.id;
        this.sectionTitle = currentSection.title;
      }
    });
  }

  setSectionCompleted(sectionId: string, sectionName: string) {
    const sectionIndex = this.sections.findIndex((section) => section.id === sectionId);
    if (sectionIndex >= 0) {
      this.sectionStatus[sectionIndex] = true;
    }

    const feature = "OB";
    const objectId = sectionId;
  
    const payload = {
      feature: feature,
      objectId: objectId,
    };
  
    this.progressService.postProgress(payload).subscribe(
      (response) => {
        setTimeout(() => {
          this.progressService.getProgress("OB").subscribe(
            (data: any) => {
              this.progressData = data.progresses;
              this.processProgressData(this.progressData);
            },
            (error) => {
              this.routeService.serverError(error);
            }
          )
        }, 500);

      },
      (error) => {
        this.routeService.serverError(error);
      }
    );
    this.tagService.completeSectionPush(sectionName);
  }

  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  stopAnimation(className: string, sectionIdForModal: string, sectionName: any) {
    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach((element) => {
      element.classList.toggle('paused');
    });
    this.tagService.sectionModalOpenPush(sectionName);
    this.openDialog(sectionIdForModal);
  }
  
  openDialog(sectionIdForModal: string) {
    const dialogRef = this.dialog.open(ModalDialogContent, {
      data: { sectionIdForModal: sectionIdForModal },
    });
  
    dialogRef.afterOpened().subscribe(() => {      
      const keydownEvent = new KeyboardEvent('keydown', {
        key: 'Alt',
        code: 'AltLeft',
        location: 1,
        altKey: true,
      });
      const keyupEvent = new KeyboardEvent('keyup', {
        key: 'Alt',
        code: 'AltLeft',
        location: 1,
        altKey: false,
      });

      setTimeout(() => {
        window.dispatchEvent(keydownEvent);
      }, 500);

      setTimeout(() => {
        window.dispatchEvent(keyupEvent);
      }, 550);
    });
  }

  ngOnDestroy(): void {
    this.showFooter();
    this.showToastSubscription?.unsubscribe();

    setTimeout(()=>{
      window.addEventListener('scroll', this.onAutoScroll, true);
    }, 100)
  }

  downloadGA4Push(){
    this.tagService.downloadPush();
  }
  
}

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'modal-dialog',
  templateUrl: 'modal-dialog.html',
})
export class ModalDialogContent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { sectionIdForModal: string },
    private translateService: TranslateService
  ) {}

  activeSlide = 1;
  totalSlides = 4;  
  isLessSlidesNeeded: any = this.totalSlides;
  language = localStorage.getItem('siteLang') || 'en-US';

  hideSlideWhenNeeded(): any {
    if (this.language == 'fr-CA' || this.language == 'en-CA') {
      this.isLessSlidesNeeded = this.totalSlides < 2;
      return this.isLessSlidesNeeded;
    }
    return false;
  }

  showPreviousButton(): boolean {
    return this.activeSlide > 1;
  }

  showNextButton(): boolean {
    return this.activeSlide < this.totalSlides;
  }

  goToNextSlide() {
    if (this.activeSlide < this.totalSlides) {
      this.activeSlide++;
      this.scrollToTop();
    }
  }

  goToPreviousSlide() {
    if (this.activeSlide > 1) {
      this.activeSlide--;
      this.scrollToTop();
    }
  }  

  scrollToTop() { 
    var el = document.getElementsByClassName("scrollPoint");
    for (let i = 0; i < el.length; i++) {
      el[i].scrollTop = 0;
    }    
  }
  
  
}